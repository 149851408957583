<template>
  <v-col class="d-flex justify-center align-center">
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="pa-4"
    >
      <v-col cols="12" md="5" lg="4" xl="3" class="px-2">
        <v-card class="pb-5">
          <v-card-title class="d-flex align-center justify-center">
            <template v-if="hasWhitelabel">
              <div class="d-flex flex-column align-center justify-center">
                <img :src="logo" height="150">
                <h2 class="pa-0 ma-0 mt-5 text-left">
                  {{ whitelabel.name }}
                </h2>
              </div>
            </template>

            <template v-else-if="!isWhitelabelLoading">
              <div class="d-flex flex-column align-center justify-center">
                <img :src="isDark ? $assets.faviconWhite : $assets.favicon" height="150">
                <h2 class="pa-0 ma-0 mt-5 text-left">
                  {{ $t('app.site') }}
                </h2>
              </div>
            </template>

            <template v-else>
              <v-sheet height="202" />
            </template>
          </v-card-title>

          <v-card-actions class="px-7 mt-2">
            <v-row no-gutters>
              <template v-if="token">
                <v-col v-if="token" cols="12" class="d-flex pa-2">
                  <v-btn
                    depressed
                    large
                    color="primary"
                    class="flex"
                    @click="$router.push('AddUnit')"
                  >
                    {{ $t('actions.addUnit') }}
                  </v-btn>
                </v-col>
                <v-col v-if="token" cols="12" class="d-flex pa-2">
                  <v-btn
                    depressed
                    large
                    outlined
                    color="primary"
                    class="flex"
                    @click="$store.dispatch('users/logout')"
                  >
                    {{ $t('users.signOut') }}
                  </v-btn>
                </v-col>
              </template>

              <template v-else>
                <v-col cols="12" class="d-flex pa-2">
                  <v-btn
                    depressed
                    large
                    color="primary"
                    class="flex"
                    @click="$router.push('Login')"
                  >
                    {{ $t('users.signIn') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="d-flex pa-2">
                  <v-btn
                    depressed
                    large
                    color="primary"
                    class="flex"
                    @click="$router.push('SignUp')"
                  >
                    {{ $t('users.signUp') }}
                  </v-btn>
                </v-col>
              </template>
              
              <v-col cols="12" class="pa-2">
                <v-select
                  v-model="language"
                  :items="availableLanguages"
                  :label="$t('languages.select')"
                  outlined
                  color="primary"
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
    }
  },

  computed: {
    ...mapState(['width', 'whitelabel', 'isDark', 'users']),

    color () {
      if (this.isDark) {
        return 'white black--text'
      }

      return 'primary white--text'
    },

    user () {
      return this.users.entity
    },

    token () {
      return this.users.entity.token
    },

    isWhitelabelLoading () {
      return this.$store.state.isWhitelabelLoading
    },

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    logo () {
      if (this.whitelabel.logo === undefined) {
        return this.$assets.logo
      }

      return this.whitelabel.logo
    },

    language: {
      get () {
        return this.$store.state.language
      },

      set (value) {
        this.$store.commit('setLanguage', value)
      }
    },

    availableLanguages () {
      return [
        { text: this.$i18n.t('languages.availableLanguages.es'), value: 'es' },
        { text: this.$i18n.t('languages.availableLanguages.en'), value: 'en' }
      ]
    }
  },

  watch: {
    language (v) {
      this.$i18n.locale = v
    }
  },

  mounted () {
    this.$store.commit('setLoading', false)
  },

  created () {
    // this.$store.dispatch('protocols/getList', false, { root: true })
  },

  methods: {
  }
}
</script>