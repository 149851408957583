<template>
  <v-col class="d-flex justify-center align-center">
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="pa-4"
    >
      <v-col cols="12" md="5" lg="4" xl="3" class="px-2">
        <v-card class="pb-5">
          <v-card-title class="text-center">
            <template v-if="hasWhitelabel">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;">
                <img :src="logo" height="50">
                <v-divider :dark="isDark" vertical class="ma-2" />
                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                  <h3 class="pa-0 ma-0 text-left" style="line-height: 0.9;">
                    {{ whitelabel.name }}
                    <br>
                  </h3>
                </div>
              </div>
            </template>

            <template v-else-if="!isWhitelabelLoading">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;">
                <img :src="isDark ? $assets.faviconWhite : $assets.favicon" height="50">
                <v-divider :dark="isDark" vertical class="ma-2" />
                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                  <h3 class="pa-0 ma-0 text-left" style="line-height: 0.9;">
                    {{ $t('actions.addUnit') }}
                    <br>
                  </h3>
                </div>
              </div>
            </template>

            <template v-else>
              <v-sheet height="50" />
            </template>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-row no-gutters class="px-3 mt-1">
              <v-col cols="12" class="text-center pa-2">
                <span>{{ $t('users.subscription') }}</span>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-stepper v-model="step">
                  <v-stepper-header>
                    <v-stepper-step step="1">{{ $t('subscription.step.one') }}</v-stepper-step>
                    <v-divider />
                    <v-stepper-step step="2">{{ $t('subscription.step.two') }}</v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card>
                        <v-card-text>
                          <v-container fluid>
                            <v-row no-gutters>
                              <v-col cols="12" class="pa-2">
                                <v-autocomplete
                                  v-model="object.productId"
                                  :items="products"
                                  item-value="id"
                                  :label="$t('subscription.title')"
                                  :error="hasErrors('productId')"
                                  :error-messages="getErrors('productId')"
                                  @change="getPlan"
                                >
                                  <template #item="{ item }">
                                    <span>{{ item.title }}</span>
                                    <v-spacer />
                                    <span>{{ item.price }} {{ item.currency }}</span>
                                  </template>
                                  <template #selection="{ item }">
                                    <span>{{ item.title }}</span>
                                    <v-spacer />
                                    <span>{{ item.price }} {{ item.currency }}</span>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" class="pa-2">
                                <v-autocomplete
                                  v-model="object.planId"
                                  :items="plans"
                                  item-value="id"
                                  :label="$t('subscription.plan')"
                                  :error="hasErrors('planId')"
                                  :error-messages="getErrors('planId')"
                                >
                                  <template #item="{ item }">
                                    <span>{{ item.name }}</span>
                                  </template>
                                  <template #selection="{ item }">
                                    <span>{{ item.name }}</span>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-space-between">
                          <v-btn
                            v-if="isEditting"
                            :ripple="false"
                            outlined
                            dark
                            color="primary"
                            to="/AddUnit"
                          >
                            {{ $t('actions.discard') }}
                          </v-btn>
                          <v-btn
                            v-if="isEditting"
                            :ripple="false"
                            depressed
                            color="primary"
                            @click="saveItem()"
                          >
                            {{ $t('actions.continue') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <v-card>
                        <v-card-text>
                          <v-simple-table>
                            <template #default>
                              <thead>
                                <tr>
                                  <th class="text-center">
                                    {{ $t('subscription.title') }}
                                  </th>
                                  <th class="text-center">
                                    {{ $t('subscription.plan') }}
                                  </th>
                                  <th class="text-center">
                                    {{ $t('subscription.total') }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-if="step===2">
                                  <td>{{ cart['lines'][0]['title'] }}</td>
                                  <td>{{ cart['lines'][0]['plan'] }}</td>
                                  <td>{{ parseFloat(cart['total']).toFixed(2) }} {{ cart['currency'] }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-space-between">
                          <v-btn
                            v-if="isEditting"
                            :ripple="false"
                            outlined
                            dark
                            color="primary"
                            @click="step=1"
                          >
                            {{ $t('actions.discard') }}
                          </v-btn>
                          <v-btn
                            v-if="isEditting"
                            :ripple="false"
                            depressed
                            color="primary"
                            @click="checkoutCart()"
                          >
                            {{ $t('actions.checkout') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
              <!-- <v-col cols="12" class="px-2">
                <v-text-field
                  v-model="data.unitName"
                  :label="$t('users.unitName')"
                  :errors="hasErrors('unitName')"
                  :error-messages="getErrors('unitName')"
                  outlined
                />
              </v-col>

              <v-col cols="12" class="px-2">
                <v-text-field
                  v-model="data.unitImei"
                  :label="$t('users.unitImei')"
                  :errors="hasErrors('unitImei')"
                  :error-messages="getErrors('unitImei')"
                  outlined
                />
              </v-col> -->
            </v-row>
          </v-card-text>

          <!-- <v-card-actions class="px-7">
            <v-row no-gutters>
              <v-col cols="12" class="d-flex pa-2">
                <v-btn
                  depressed
                  large
                  color="primary"
                  class="flex"
                  @click="handleAddUnit"
                >
                  {{ $t('actions.addUnit') }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex pa-2">
                <v-btn
                  depressed
                  large
                  color="secondary"
                  text
                  class="flex"
                  to="/Home"
                >
                  {{ $t('actions.goBack') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { orm } from '@/mixins'
import addMutation from '@/graphql/mutations/shopify/cart/add.gql'
// import editMutation from '@/graphql/mutations/accounts/edit.gql'
import checkoutMutation from '@/graphql/mutations/shopify/cart/checkout.gql'

export default {

  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    showDialog: { type: Boolean, default: () => false },
    data: { type: Object, default: () => {} },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      show: false,
      errors: {},
      step: 1,
      cart: {},
      plans: [],
      object: {
        productId: '',
        planId: '',
        ident: ''
      }
    }
  },

  computed: {
    ...mapState(['isDark', 'whitelabel', 'users', 'width', 'shopify']),
    ...mapGetters(['color', 'isMobile']),

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    isEditting: {
      get () {
        return !this.readonly
      },

      set (value) {
        this.$emit('set-readonly', value)
      }
    },

    title () {
      return 'Subscription'
    },

    products () {
      return this.shopify.products
    },
    isWhitelabelLoading () {
      return this.$store.state.isWhitelabelLoading
    },

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    logo () {
      if (this.whitelabel.logo === undefined) {
        return this.$assets.logo
      }

      return this.whitelabel.logo
    }
  },

  created () {
    if (this.products.length === 1) {
      this.object.productId = this.products[0].id
      this.getPlan(this.object.productId)
    }
  },

  methods: {
    getPlan (index) {
      this.plans = this.shopify.products.find(x => x.id === index).subscription
      if (this.plans.length > 0) {
        this.object.planId = this.plans[0].id
      }
    },
    async saveItem (id) {
      this.isLoading = true
      const data = {
        id: id,
        productId: this.object.productId,
        planId: this.object.planId,
        ident: this.$route.params.data.unitIdent
      }
      if (id) {
        await this.edit(data)
      } else {
        await this.add(data)
      }
    },

    async checkoutCart () {
      this.isLoading = true
      await this.checkout(this.cart.cartId)
    },

    // goToCheckout () {
    //   this.$router.push('Home')
    //   this.$store.commit('toggleSnackbar', {
    //     color: 'green darken-2',
    //     message: this.$t('helpers.payment.message')
    //   })
    //   window.open(this.cart.url)
    // },

    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      switch (status) {
        case 'OK':
          this.resetErrors()
          if (action === 'add' || action === 'edit') {
            this.$store.commit('toggleSnackbar', {
              color: 'green darken-2',
              message: this.$t('helpers.saved.successfully')
            })
            this.cart = {
              cartId: result.id,
              lines: result.lines,
              total: result.total,
              currency: result.currency,
              url: result.checkoutUrl
            }
            console.log(this.cart)
            this.step = 2
          }
          if (action === 'checkout') {
            this.$router.push('Home')
            this.$store.commit('toggleSnackbar', {
              color: 'green darken-2',
              message: this.$t('helpers.payment.message')
            })
            window.open(result.checkoutUrl)
          }
          break
        case 'UNPROCESSABLE':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'LIMITREACHED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.limit_reached')
          })
          break
      }
    },

    add (data) {
      const action = 'add'
      this.$apollo.mutate({
        mutation: addMutation,
        variables: {
          token: this.users.entity.token,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.addCart
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Subscription Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    // edit (data) {
    //   const action = 'edit'
    //   this.$apollo.mutate({
    //     mutation: editMutation,
    //     variables: {
    //       token: this.users.entity.token,
    //       data
    //     },
    //     fetchPolicy: 'no-cache'
    //   }).then(response => {
    //     const { status, errors, result } = response.data.editAccount
    //     this.evaluateResponse({ status, errors, result, action })
    //   }).catch(err => {
    //     console.log('[Subscription Dialog] Error handled', err)
    //     this.$store.commit('toggleSnackbar', {
    //       color: 'red darken-2',
    //       message: this.$t('helpers.errors.disaster')
    //     })
    //   })
    // },

    checkout (id) {
      console.log('id', id)
      const action = 'checkout'
      this.$apollo.mutate({
        mutation: checkoutMutation,
        variables: {
          token: this.users.entity.token,
          cartId: id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.checkoutCart
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Subscription Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    discardItem () {
      this.dialog = false
      this.object = Object.assign({}, this.$store.getters['shopify/defaultObject'])
      this.resetErrors()
    }
  }
}
</script>