// Libraries
import router from '@/plugins/router'
// import cookies from 'js-cookie'
import { i18n } from '@/plugins'

// graphql files
import login from 'GraphQL/mutations/utils/session.gql'
import list from 'GraphQL/queries/users/list.gql'

// GraphQL initializer
import { apollo } from '@/plugins/apollo'

export default {
  async getList ({ state, dispatch, commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        token: rootState.users.entity.token,
        language: rootState.language
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.users

      switch (status) {
        case 'OK':
          commit('setList', result)
          break

        case 'UNAUTHORIZED':
          commit('toggleSnackbar', { message: 'Acceso denegado', color: 'red darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  },

  logout ({ state, dispatch, commit, rootState }, payload) {
    commit('setLoading', true, { root: true })

    localStorage.removeItem('wialonId')
    localStorage.removeItem('username')
    localStorage.removeItem('token')

    commit('setEntity', {})
    router.push('/Home')

    setTimeout(() => {
      commit('setLoading', false, { root: true })
    }, 500)
  },

  async login ({ state, dispatch, commit, rootState }, payload) {
    commit('setLoading', true, { root: true })

    const data = {
      username: payload.username,
      password: payload.password,
      url: window.location.hostname
    }
    await apollo.mutate({
      mutation: login,
      variables: {
        ...data
      }
    }).then((response) => {
      const { status, errors, result } = response.data.loginWialon

      switch (status) {
        case 'OK':
          if (!['WialonUser'].includes(result.__typename)) {
            commit('toggleSnackbar', {
              message: 'Acceso denegado',
              color: 'red darken-2'
            }, { root: true })
            router.push('/Login')
          } else {
            localStorage.setItem('wialonId', result.wialonId)
            localStorage.setItem('username', result.username)
            localStorage.setItem('token', result.token)

            commit('setEntity', {
              ...result,
              loaded: true
            })

            dispatch('loadData')
          }
          break

        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'warning' }, { root: true })
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', {
            message: errors.username[0],
            asLogin: true,
            color: 'warning',
            duration: 10000
          }, { root: true })
          break

        case 'NOTFOUND':
          commit('toggleSnackbar', {
            message: i18n.t('errors.user.notFound'),
            asLogin: true,
            color: 'warning',
            duration: 10000
          }, { root: true })
          break

        case 'ACCESSDENIED':
          commit('toggleSnackbar', {
            message: i18n.t('errors.unauthorized'),
            asLogin: true,
            color: 'warning',
            duration: 10000
          }, { root: true })
          break

        case 'UNAUTHORIZED':
          commit('toggleSnackbar', {
            message: i18n.t('errors.user.notFound'),
            asLogin: true,
            color: 'warning',
            duration: 10000
          }, { root: true })
          break
        
        case 'SERVICEUNAVAILABLE':
          commit('toggleSnackbar', {
            message: i18n.t('errors.service.unavailable'),
            asLogin: true,
            color: 'warning',
            duration: 10000
          }, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async loadData ({ state, dispatch, commit, rootState }, payload) {
    const lastUrl = window.location.href.split('#')[1]
    await dispatch('shopify/getProducts', false, { root: true })

    await commit('setLoading', false, { root: true })

    if (payload === undefined) {
      let redirect = lastUrl

      if (lastUrl === '/Login') {
        redirect = '/Home'
      }

      router.push(redirect)
    } else {
      router.push('/Home')
    }
  }
}
