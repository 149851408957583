/*
  Products actions
*/
// GraphQL initializer
// graphql files
// Libraries

import list from 'GraphQL/queries/shopify/products/list.gql'
import { apollo } from '@/plugins/apollo'

export default {
  async getProducts ({ state, dispatch, commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        token: rootState.users.entity.token
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.products

      switch (status) {
        case 'OK':
          commit('setProducts', result)
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  }
}
