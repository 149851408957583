/*
  Service actions
*/
import { i18n } from '@/plugins/i18n'
// import router from '@/plugins/router'

// graphql files
import list from 'GraphQL/queries/utils/countries.gql'
import addUnit from 'GraphQL/mutations/units/add.gql'
import { apollo } from '@/plugins/apollo'

export default {
  async getCountries ({ state, dispatch, commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      fetchPolicy: 'no-cache'
    }).then((response) => {
      console.log('Result', response.data)
      const { status, result } = response.data.countries

      switch (status) {
        case 'OK':
          commit('setCountries', result)
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  },
  async addUnit ({ commit }, payload) {
    commit('setLoading', true, { root: true })

    await apollo.mutate({
      mutation: addUnit,
      variables: {
        username: payload.username,
        wialonId: payload.wialonId,
        url: payload.url,
        data: payload.data
      }
    }).then(response => {
      const { status, errors } = response.data.addWialonUnit

      switch (status) {
        case 'OK':
          commit('toggle', { method: 'add', status, errors, result: null })
          commit('toggleSnackbar', { message: i18n.t('subscription.message'), color: 'success' }, { root: true })
          // commit('toggleSnackbar', { message: i18n.t('helpers.save.success'), color: 'success' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggle', { method: 'add', status, errors, result: null })
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'warning' }, { root: true })
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  }
}
