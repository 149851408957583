import Vue from 'vue'
import App from './App.vue'
import { i18n, vuetify, assets, router, store, cookies, apollo } from '@/plugins'
import DatetimePicker from '@goldenm/vuetify-datetime-picker'

import whitelabel from 'GraphQL/queries/whitelabels/detail.gql'

// import axios from 'axios'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '@/assets/application.scss'

Vue.config.productionTip = false

Vue.use(assets)
Vue.use(cookies)
Vue.use(DatetimePicker)

new Vue({
  i18n,
  vuetify,
  router,
  store,
  apolloProvider: apollo,

  computed: {
    messages () {
      return this.$i18n.messages
    },

    title () {
      if (this.whitelabel.name === undefined) {
        return this.$i18n.t('app.site')
      }

      return this.whitelabel.name
    },

    whitelabel () {
      return this.$store.state.whitelabel
    }
  },

  watch: {
    $route (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${newVal.name}`)} | ${this.title}`
    },

    messages (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
    },

    whitelabel (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
    }
  },

  beforeCreate () {
    window.addEventListener('resize', () => {
      this.$store.commit('setWidth', window.innerWidth)

      if (window.innerWidth <= 930) {
        this.$store.commit('setMini', false)
      }
    })

    const favicon = document.getElementById('favicon')

    this.$apollo.query({
      query: whitelabel,
      variables: {
        url: window.location.hostname
      },
      fetchPolicy: 'no-cache'
    }).then(async (response) => {
      const { status, result } = response.data.whitelabel

      switch (status) {
        case 'OK':
          this.$vuetify.theme.themes.light.primary = result.primaryColor.substring(0, 7)
          this.$vuetify.theme.themes.light.secondary = result.secondaryColor.substring(0, 7)
          this.$vuetify.theme.themes.light.accent = result.accentColor.substring(0, 7)
          this.$vuetify.theme.themes.dark.primary = result.primaryColor.substring(0, 7)
          this.$vuetify.theme.themes.dark.secondary = result.secondaryColor.substring(0, 7)
          this.$vuetify.theme.themes.dark.accent = result.accentColor.substring(0, 7)
          this.$store.commit('setWhitelabel', result)
          document.title = this.title
          favicon.href = result.logo
          break

        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar')
          break

        case 'NOTFOUND':
          this.$router.push('/NoApp')
          this.$store.commit('setLoading', false)
          break
      }
    }).catch(() => {
      this.$store.commit('toggleSnackbar')
    }).finally(() => {
      this.$store.commit('setIsWhitelabelLoading', false)
    })

    document.title = this.title

    const cookies = this.$cookies.get('state')
    if (cookies !== undefined) {
      this.$store.commit('setCookies', cookies === 'false')
    }

    const dark = this.$cookies.get('dark')
    if (dark !== undefined) {
      this.$store.commit('setDark', dark === 'true')
      this.$vuetify.theme.dark = dark === 'true'
    }

    const language = this.$cookies.get('language')
    if (language !== undefined) {
      this.$store.commit('setLanguage', language)
      this.$i18n.locale = language
    } else {
      this.$store.commit('setLanguage', this.$i18n.locale)
    }
  },

  created () {
    const wialonId = localStorage.getItem('wialonId')
    const username = localStorage.getItem('username')
    const token = localStorage.getItem('token')

    if (token === null || wialonId === null) {
      this.$router.push('/Home')
      this.$store.commit('setLoading', false)
    } else {
      this.$store.commit('users/setEntity', {
        wialonId,
        username,
        token
      })
      this.$store.dispatch('users/loadData')
      // this.$router.push('/Home')
      this.$store.commit('setLoading', false)
    }

    // const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/keys/fetch' : `https://${window.location.hostname}/api/keys/fetch`
    // axios.get(url).then((response) => {
    //   this.$store.commit('languages/setDefaultKeys', response.data)
    // })
  },

  mounted () {
    document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
  },

  beforeDestroy () {
    window.removeEventListener('resize')
  },

  render: h => h(App)
}).$mount('#app')
