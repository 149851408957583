import LoginLayout from '@/layouts/Login.vue'
// import AppLayout from '@/layouts/Main.vue'

import NotFound from '@/views/NotFound.vue'
import Home from '@/views/login/Home.vue'
import SignIn from '@/views/login/SignIn.vue'
import SignUp from '@/views/login/SignUp.vue'
import Recovery from '@/views/Recovery.vue'
import AddUnit from '@/views/AddUnit.vue'
import Subscription from '@/views/Subscription.vue'
// import Home from '@/views/Home.vue'
// import Profile from '@/views/Profile.vue'

export default [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/',
    component: LoginLayout,
    children: [
      {
        path: 'Home',
        name: 'home',
        components: { default: Home }
      },
      {
        path: 'Login',
        name: 'login',
        components: { default: SignIn }
      },
      {
        path: 'SignUp',
        name: 'signUp',
        components: { default: SignUp }
      },
      {
        path: 'Recovery',
        name: 'recovery',
        components: { default: Recovery }
      },
      {
        path: 'AddUnit',
        name: 'addUnit',
        components: { default: AddUnit }
      },
      {
        path: 'Subscription',
        name: 'subscription',
        components: { default: Subscription }
      }
    ]
  },
  // {
  //   path: '/',
  //   component: AppLayout,
  //   children: [
  //     {
  //       path: 'Profile',
  //       name: 'profile',
  //       components: { default: Profile }
  //     }
  //   ]
  // },
  {
    path: '*',
    component: NotFound
  }
]