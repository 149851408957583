<template>
  <v-col class="d-flex justify-center align-center">
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="pa-4"
    >
      <v-col cols="12" md="5" lg="4" xl="3" class="px-2">
        <v-card class="pb-5">
          <v-card-title class="text-center">
            <template v-if="hasWhitelabel">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;">
                <img :src="logo" height="50">
                <v-divider :dark="isDark" vertical class="ma-2" />
                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                  <h3 class="pa-0 ma-0 text-left" style="line-height: 0.9;">
                    {{ whitelabel.name }}
                    <br>
                  </h3>
                </div>
              </div>
            </template>

            <template v-else-if="!isWhitelabelLoading">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;">
                <img :src="isDark ? $assets.faviconWhite : $assets.favicon" height="50">
                <v-divider :dark="isDark" vertical class="ma-2" />
                <div style="display: flex; flex-direction: column; align-items: flex-start;">
                  <h3 class="pa-0 ma-0 text-left" style="line-height: 0.9;">
                    {{ $t('users.signUp') }}
                    <br>
                  </h3>
                </div>
              </div>
            </template>

            <template v-else>
              <v-sheet height="50" />
            </template>
          </v-card-title>

          <v-card-text class="pb-0">
            <v-row no-gutters class="px-3 mt-5">
              <v-col cols="12" class="text-center pa-2">
                <span>{{ $t('users.basicInformation') }}</span>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-text-field
                  v-model="credentials.username"
                  :label="$t('users.username')"
                  :errors="hasErrors('username')"
                  :error-messages="getErrors('username')"
                  outlined
                />
              </v-col>

              <v-col cols="12" class="px-2">
                <v-text-field
                  v-model="credentials.password"
                  :label="$t('users.password')"
                  :errors="hasErrors('password')"
                  :error-messages="getErrors('password')"
                  outlined
                  type="password"
                />
              </v-col>

              <v-col cols="12" class="px-2">
                <v-text-field
                  v-model="credentials.email"
                  :label="$t('users.email')"
                  :errors="hasErrors('email')"
                  :error-messages="getErrors('email')"
                  outlined
                />
              </v-col>
            </v-row>

            <v-row no-gutters class="px-3 mt-1">
              <v-col cols="12" class="text-center pa-2">
                <span>{{ $t('users.unitInformation') }}</span>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-text-field
                  v-model="credentials.unitName"
                  :label="$t('users.unitName')"
                  :errors="hasErrors('unitName')"
                  :error-messages="getErrors('unitName')"
                  :hint="$t('helpers.unitName.hint')"
                  persistent-hint
                  outlined
                />
              </v-col>

              <v-col cols="12" class="px-2">
                <v-text-field
                  v-model="credentials.unitIdent"
                  :label="$t('users.unitIdent')"
                  :errors="hasErrors('unitIdent')"
                  :error-messages="getErrors('unitIdent')"
                  :hint="$t('helpers.unitIdent.hint')"
                  persistent-hint
                  outlined
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="px-7">
            <v-row no-gutters>
              <v-col cols="12" class="d-flex pa-2">
                <v-btn
                  depressed
                  large
                  color="primary"
                  outlined
                  class="flex"
                  @click="signUp"
                >
                  {{ $t('users.signUp') }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="d-flex pa-2">
                <v-btn
                  depressed
                  large
                  color="secondary"
                  text
                  class="flex"
                  @click="$router.back()"
                >
                  {{ $t('actions.goBack') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'
import { orm } from '@/mixins'
import signUp from 'GraphQL/mutations/users/signUp.gql'

export default {
  mixins: [orm],

  data () {
    return {
      errors: {},
      credentials: {
        username: '',
        password: '',
        email: '',
        unitName: '',
        unitIdent: ''
      }
    }
  },

  computed: {
    ...mapState(['width', 'whitelabel', 'isDark']),

    color () {
      if (this.isDark) {
        return 'white black--text'
      }

      return 'primary white--text'
    },

    isWhitelabelLoading () {
      return this.$store.state.isWhitelabelLoading
    },

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    logo () {
      if (this.whitelabel.logo === undefined) {
        return this.$assets.logo
      }

      return this.whitelabel.logo
    }
  },

  mounted () {
    this.$store.commit('setLoading', false)
  },

  created () {
    // this.$store.dispatch('protocols/getList', false, { root: true })
  },

  methods: {
    async signUp () {
      this.$store.commit('setLoading', true)
      await this.$apollo.mutate({
        mutation: signUp,
        variables: {
          url: window.location.hostname,
          data: this.credentials
        }
      }).then((response) => {
        const { status, errors, result } = response.data.addWialonUser
        switch (status) {
          case 'OK':
            this.resetErrors()
            this.$store.commit('toggleSnackbar', {
              color: 'green darken-2',
              message: this.$t('helpers.saved.successfully')
            })
            this.login(result)
            break
          case 'BADREQUEST':
            this.errors = this.parseErrors(errors)
            this.$store.commit('toggleSnackbar', {
              color: 'orange darken-2',
              message: this.$t('errors.invalidFields')
            })
            break
          default:
            this.resetErrors()
            this.$store.commit('toggleSnackbar')
        }
      }).catch((error) => {
        console.error(error)
        this.resetErrors()
        this.$store.commit('toggleSnackbar')
      }).finally(() => {
        this.$store.commit('setLoading', false)
      })
    },
    login (result) {
      const wialonId = result.wialonId
      const username = result.username
      const token = result.token
      localStorage.setItem('wialonId', wialonId)
      localStorage.setItem('username', username)
      localStorage.setItem('token', token)
      this.$store.commit('users/setEntity', {
        wialonId,
        username,
        token
      })
      this.$store.dispatch('shopify/getProducts')
      this.$router.push({
        name: 'subscription',
        params: {
          username: this.credentials.username,
          url: window.location.hostname,
          data: this.credentials
        }
      })
    }
  }
}
</script>