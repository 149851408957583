import users from './users'
import languages from './languages'
import dealers from './dealers'
import shopify from './shopify'

export default {
  users,
  languages,
  dealers,
  shopify
}